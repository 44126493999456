<template>
    <div class="relative w-full py-6 text-base bg-primary">
        <div
            class="container relative grid grid-cols-1 gap-y-8 text-primary sm:gap-y-0 md:grid-cols-4"
        >
            <div class="flex items-center order-3 text-sm sm:pr-4 sm:order-1">
                © {{ $dayjs().year() }} Raphael Bernhart | All Rights Reserved
            </div>
            <div
                class="flex flex-wrap items-center order-1 sm:col-span-3 gap-x-6 sm:mt-4 md:mt-0 gap-y-4 sm:order-2 md:gap-y-0"
            >
                <nuxt-link
                    :to="localePath('/imprint')"
                    class="underline transition-colors hover:text-gray-300 underline-offset-4"
                >
                    {{ $t('footer.imprint') }}
                </nuxt-link>
                <nuxt-link
                    :to="localePath('/imprint#datenschutzerklärung')"
                    class="underline transition-colors hover:text-gray-300 underline-offset-4"
                >
                    {{ $t('footer.privacy') }}
                </nuxt-link>
                <nuxt-link
                    :to="localePath('/agb')"
                    class="underline transition-colors hover:text-gray-300 underline-offset-4"
                >
                    {{ $t('footer.agb') }}
                </nuxt-link>
            </div>
            <div
                class="relative bottom-0 right-0 flex items-center order-2 text-sm sm:absolute sm:order-3 md:bottom-auto"
            >
                <span>
                    <a
                        href="https://github.com/raphaelbernhart/portfolio"
                        target="_blank"
                        class="font-bold"
                    >
                        v{{ $config.public.projectVersion }}
                    </a>
                    made with ♥️ by
                    <a
                        href="https://github.com/raphaelbernhart"
                        target="_blank"
                        class="font-bold"
                    >
                        @raphaelbernhart
                    </a>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
    name: 'RightsBar',
});
</script>
